<template>
  <Header />
  <router-view />
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Fjalla+One&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Fjalla One', sans-serif;
}
.container {
  padding-top: 9rem;
  width: 100%;
  max-width: 1490px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem;
}
.container--full-bleed {
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .container {
    padding-top: 7.5rem;
  }
  .container--full-bleed {
    padding-top: 0;
  }
}

img {
  max-width: 100%;
  object-fit: contain;
}
</style>
