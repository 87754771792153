<template>
    <div class="image-grid">
        <div
            v-for="img, i in active_images"
            :key="img.public_id"
            class="image-grid__item"
        >
            <img
                :src="img.secure_url"
                :alt="img.public_id"
                class="image-grid__image"
                @click="showModal(img)"
                :loading="i < 6 ? 'eager' : 'lazy'"
            />
        </div>
        <div
            class="image-grid__focus-modal"
            :class="show_modal ? 'image-grid__focus-modal--reveal' : ''"
            @click.self="hideModal"
        >
            <img
                v-if="focused_image"
                class="image-grid__focus-image"
                :src="focused_image.secure_url"
                :alt="focused_image.public_id"
                @click.self="hideModal"
            />
            <div class="image-grid__focus-nav">
                <button
                    class="image-grid__focus-nav-left"
                    @click="showPrev"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
                    </svg>
                </button>
                <button
                    class="image-grid__focus-nav-right"
                    @click="showNext"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/>
                    </svg>
                </button>
            </div>
            <button
                class="image-grid__close-focus"
                @click="hideModal"
            >
                Close
                <svg class="image-grid__close-focus-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/>
                </svg>
            </button>
        </div>
        <div id="pagination-trigger" class="pagination-trigger"></div>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            page: 1,
            focused_image: null,
            show_modal: false,
            observer: null
        }
    },
    computed: {
        active_images() {
            return this.images.slice( 0, this.page * 9 - 1 );
        }
    },  
    methods: {
        showModal(img) {
            this.focused_image = img;
            this.show_modal = true;
        },
        hideModal() {
            this.show_modal = false;
        },
        showNext() {
            const current_index = this.images.indexOf(this.focused_image);
            const next_index = current_index === this.images.length - 1 ? 0 : current_index + 1;
            this.focused_image = this.images[next_index];
        },
        showPrev() {
            const current_index = this.images.indexOf(this.focused_image);
            const previous_index = current_index === 0 ? this.images.length - 1 : current_index - 1;
            this.focused_image = this.images[previous_index];
        }
    },
    mounted() {
        const pagination_trigger = document.querySelector('#pagination-trigger');
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.page++
                }
            });
        });
        this.observer.observe(pagination_trigger);
    }
}
</script>

<style>
    .image-grid {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.5rem;
        justify-content: space-between;
    }

    .image-grid__item {
        width: calc(33.33333% - 1rem);
        aspect-ratio: 1 / 1;
        overflow: hidden;
        margin-bottom: 1.5rem;
    }

    .image-grid__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 30%;
        cursor: pointer;
    }

    .image-grid__focus-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
        background: rgba( 0, 0, 0, 0.85);
        transition: opacity 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        box-sizing: border-box;
    }
    .image-grid__focus-modal--reveal {
        opacity: 1;
        pointer-events: all;
    }

    .image-grid__close-focus {
        position: fixed;
        top: 1rem;
        right: 1rem;
        font-size: 1.5rem;
        color: white;
        background: none;
        border: 0;
        cursor: pointer;
        padding: 0.25rem 0.5rem;
        display: flex;
        align-items: center;
    }
    .image-grid__close-focus-icon {
        fill: white;
        width: 1rem;
        height: 1rem;
        margin-left: 0.5rem;
    }

    .image-grid__focus-image {
        max-width: 100%;
        max-height: 100%;
    }

    .image-grid__focus-nav {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        pointer-events: none;
    }
    .image-grid__focus-nav-left,
    .image-grid__focus-nav-right {
        height: 100%;
        margin: 0 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        pointer-events: all;
        cursor: pointer;
        padding: 0 1rem;
        opacity: 0.75;
    }
    .image-grid__focus-nav-left svg,
    .image-grid__focus-nav-right svg {
        fill: white;
        width: 4rem;
        height: 4rem;
    }

    @media only screen and (max-width: 1400px) {
        .image-grid__focus-nav-left,
        .image-grid__focus-nav-right {
            margin: 0 1.5rem
        }

        .image-grid__focus-nav-left svg,
        .image-grid__focus-nav-right svg {
            width: 3rem;
            height: 3rem;
        }
    }

    @media only screen and (max-width: 700px) {
        .image-grid {
            column-gap: 0.5rem;
        }

        .image-grid__focus-modal {
            padding: 0;
        }

        .image-grid__focus-nav-left,
        .image-grid__focus-nav-right {
            margin: 0;
        }
        
        .image-grid__focus-nav-left svg,
        .image-grid__focus-nav-right svg {
            width: 2rem;
            height: 2rem;
        }

        .image-grid__item {
            width: calc(50% - 0.25rem);
            aspect-ratio: 1 / 1;
            overflow: hidden;
            margin-bottom: 0.5rem;
        }
    }

     @media only screen and (max-width: 480px) {
        .image-grid {
            gap: 0;
        }
    
        .image-grid__item {
            width: 100%;
            aspect-ratio: auto;
            overflow: hidden;
            border-bottom: 1px solid black;
            margin-bottom: 0;
        }
    }
</style>