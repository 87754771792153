<template>
    <div class="container container--full-bleed container--no-bottom-padding">
        <img
            class="home-gif"
            src="img/willemandsophiesmaller.gif"
            alt="Willem and Sophie"
        />
    </div>
</template>

<script>
export default {
    inject: ['digital_images', 'disposable_images', 'analog_images']
}
</script>

<style>
    .home-gif {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
</style>