import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/Home.vue";
import Digital from "./views/Digital.vue";
import Disposable from "./views/Disposable.vue";
import Analog from "./views/Analog.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/digital",
        name: "Digital",
        component: Digital,
    },
    {
        path: "/disposable",
        name: "Disposable",
        component: Disposable,
    },
    {
        path: "/analog",
        name: "Analog",
        component: Analog,
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
