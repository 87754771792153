<template>
    <div class="container">
        <ImageGrid
            v-if="images"
            :images="images"
        />
        <div
            v-else-if="loading"
            class="fallback"
        >
            <h2>Loading...</h2>
        </div>
        <div
            v-else
            class="fallback"
        >
            <h2>Couldn't find images</h2>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ImageGrid from '../components/ImageGrid.vue';

export default {
    components: {
        ImageGrid
    },
    data() {
        return {
            images: null,
            loading: true
        }
    },
    async mounted() {
        if (!this.images || !this.images.length) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/images/digital`);
                this.images = response.data.resources.sort((a, b) => (a.public_id > b.public_id) ? 1 : -1);
            } catch(error) {
                console.log(error);
            }
            this.loading = false
        }
    },
}
</script>

<style scoped>
    .fallback {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 15rem);
    }
</style>