<template>
    <header
        class="header"
        :class="current_path === '/' ? 'header--transparent' : ''"
    >
        <nav class="header__nav">
            <div class="header__top-nav">
            <router-link
                class="header__logo"
                to="/"
            >
                <span class="header__logo-main">
                    WILLEM & SOPHIE
                </span>
                <span class="header__logo-sub">
                    03 / 06 / 2022 &nbsp; — &nbsp; AMSTERDAM
                </span>
            </router-link>
            </div>
            <div class="header__bottom-nav">
                <router-link
                    class="header__bottom-nav-item"
                    :class="current_path === '/' ? 'header__bottom-nav-item--active' : ''"
                    to="/"
                >
                    HOME
                </router-link>
                <router-link
                    class="header__bottom-nav-item"
                    :class="current_path === '/digital' ? 'header__bottom-nav-item--active' : ''"
                    to="/digital"
                >
                    DIGITAL
                </router-link>
                <router-link
                    class="header__bottom-nav-item"
                    :class="current_path === '/analog' ? 'header__bottom-nav-item--active' : ''"
                    to="/analog"
                >
                    ANALOG
                </router-link>
                <router-link
                    class="header__bottom-nav-item"
                    :class="current_path === '/disposable' ? 'header__bottom-nav-item--active' : ''"
                    to="/disposable"
                >
                    DISPOSABLE
                </router-link>
            </div>
        </nav>
        <div
            v-if="current_path !== '/' && !hint_seen"
            class="header__hint-banner"
        >
            <div class="header__hint-banner-content">
                <span class="header__hint-banner-text">
                    For optimal viewing, click to enlarge and use the navigation buttons.
                </span>
                <span
                    class="header__hint-banner-close"
                    @click="hint_seen = true"
                >
                    Close
                    <svg
                    class="header__hint-banner-close-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    >
                        <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/>
                    </svg>
                </span>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    computed: {
        current_path() {
            return this.$route.path;
        }
    },
    data() {
        return {
            hint_seen: false
        }
    }
}
</script>

<style>
    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        background: white;
        padding: 0.75rem 0 0;
        box-sizing: border-box;
        min-height: 9rem;
    }
    .header--transparent {
        background: transparent;
        border: 0;
    }

    .header__nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid black;
        box-sizing: border-box;
        padding-bottom: 1rem;
    }
    .header--transparent .header__nav {
        border: 0;
    }

    .header__top-nav {
        margin-bottom: 1rem;
    }

    .header__bottom-nav {
        display: flex;
        column-gap: 3rem;
    }

    .header__bottom-nav-item {
        text-align: center;
        text-decoration: none;
        color: black;
        border: 1px solid transparent;
        padding: 0.25rem 0.5rem;
        transition: all 0.2s ease;
    }
    .header__bottom-nav-item:hover {
        border-color: black;
    }
    .header__bottom-nav-item--active {
        border-color: black;
        background-color: black;
        color: white;
    }

    .header__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: black;
    }

    .header__logo-main {
        font-size: 2.5rem;
    }

    .header__hint-banner {
        background-color: black;
    }

    .header__hint-banner-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        background-color: black;
        width: 100%;
        max-width: 1490px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
    }

    .header__hint-banner-text {
        color: white;
    }

    .header__hint-banner-close {
        color: white;
        cursor: pointer;
    }
    .header__hint-banner-close-icon {
        fill: white;
        width: 0.75rem;
        height: 0.75rem;
    }

    @media only screen and (max-width: 1490px) {
        .header__hint-banner-content {
            padding: 0.5rem 1rem;
        }
    }

    @media only screen and (max-width: 700px) {
        .header {
            min-height: 7.5rem;
        }

        .header__logo-main {
            font-size: 1.5rem;
        }
    }

     @media only screen and (max-width: 480px) {
        .header__bottom-nav {
            column-gap: 0.75rem;
        }
        .header__hint-banner {
            display: none;
        }
    }
</style>